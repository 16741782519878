function ErrorBoundaryFallback({errorId}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '50vh',
        marginBottom: 'auto',
        flexDirection: 'column',
      }}
    >
      <p style={{fontSize: '30px', color: 'black', fontWeight: 'bold'}}>
        An internal error occurred. Please contact support or try again later.
      </p>
      {errorId && (
        <p style={{fontSize: '26px', color: 'black', fontWeight: 'bold'}}>
          Your error ID: <span style={{fontSize: '26px', color: 'red'}}>{errorId}</span>
        </p>
      )}
    </div>
  );
}

export default ErrorBoundaryFallback;
