import {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './EmailPreview.module.scss';

const EmailPreview = ({html, css = '', className = ''}) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;

    const adjustIframeHeight = () => {
      try {
        const iframeDocument = iframe?.contentWindow?.document;
        const iframeBody = iframeDocument?.body;
        if (iframeBody) {
          iframe.style.height = `${iframeBody.scrollHeight}px`;
        }
      } catch (error) {
        console.error('Error set up height iframe:', error);
      }
    };

    const injectCSS = (doc) => {
      if (doc?.head) {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
          ${css}
          .es-footer, .esd-hidden-footer, #footer-content {
            display: none;
            overflow: hidden;
            width: 0;
            max-height: 0;
            line-height: 0;
            mso-hide: all;
          }
        `;
        doc.head.appendChild(style);
      }
    };

    const updateIframeContent = () => {
      try {
        if (iframe) {
          const doc = iframe.contentDocument || iframe.contentWindow?.document;
          if (doc) {
            doc.open();
            doc.write(html);
            doc.close();
            injectCSS(doc);
            adjustIframeHeight();
          }
        }
      } catch (error) {
        console.error('Error update iframe:', error);
      }
    };

    iframe.onload = updateIframeContent;

    updateIframeContent();
  }, [html, css]);

  return (
    <div className={className}>
      <iframe
        ref={iframeRef}
        srcDoc={html}
        className={styles.preview}
        title="email preview"
        style={{width: '100%', border: 'none'}}
      />
    </div>
  );
};

EmailPreview.propTypes = {
  html: PropTypes.string.isRequired,
  css: PropTypes.string,
  className: PropTypes.string,
};

export default EmailPreview;
